import { SplashScreen } from '@capacitor/splash-screen';
import Mustache from 'mustache';
import * as partials from './views';

import Game from './game/game';
import Controls from './game/controls';
import { isIOS, isMobile } from './game/helpers';

(async () => {
  await SplashScreen.show({
    showDuration: 2000,
    autoHide: true,
  });

  const startGame = async () => {
    if (isMobile) {
      document.body.classList.toggle('mobile', true);
    }
    if (isIOS) {
      document.body.classList.toggle('ios', true);
    }

    const app = document.getElementById('app');
    if (app) {
      app.innerHTML = Mustache.render(partials.index, {}, partials);
    }

    const game = new Game('boardCanvas', 'nextPillCanvas');
    await game.init();
    const controls = new Controls(game);
    await controls.init();
  };

  if (document.body) {
    startGame();
  } else {
    document.addEventListener('DOMContentLoaded', () => {
      startGame();
    });
  }
})();
