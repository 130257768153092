import {
  AdLoadInfo,
  AdMob,
  AdmobConsentStatus,
  AdOptions,
  BannerAdOptions,
  BannerAdSize,
  BannerAdPosition,
  BannerAdPluginEvents,
  AdMobBannerSize,
  InterstitialAdPluginEvents,
} from '@capacitor-community/admob';

export class Ads {
  private isTesting = false;
  private npa = false;

  constructor() {
    this.isTesting = true;
  }

  async init() {
    await AdMob.initialize();

    const [trackingInfo, consentInfo] = await Promise.all([
      AdMob.trackingAuthorizationStatus(),
      AdMob.requestConsentInfo(),
    ]);

    if (trackingInfo.status === 'notDetermined') {
      /**
       * If you want to explain TrackingAuthorization before showing the iOS dialog,
       * you can show the modal here.
       * ex)
       * const modal = await this.modalCtrl.create({
       *   component: RequestTrackingPage,
       * });
       * await modal.present();
       * await modal.onDidDismiss();  // Wait for close modal
       **/

      await AdMob.requestTrackingAuthorization();
    }

    const authorizationStatus = await AdMob.trackingAuthorizationStatus();
    if (
      authorizationStatus.status === 'authorized' &&
      consentInfo.isConsentFormAvailable &&
      consentInfo.status === AdmobConsentStatus.REQUIRED
    ) {
      await AdMob.showConsentForm();
    }
  }

  async showBanner() {
    AdMob.addListener(BannerAdPluginEvents.Loaded, () => {
      // Subscribe Banner Event Listener
    });

    AdMob.addListener(BannerAdPluginEvents.SizeChanged, (size: AdMobBannerSize) => {
      // Subscribe Change Banner Size
      console.log('AdMobBannerSize', size);
    });

    // android: ca-app-pub-6997870066717711/8177702482
    // ios: ca-app-pub-6997870066717711/3583851353

    const adId = 'ca-app-pub-6997870066717711/3583851353';

    const options: BannerAdOptions = {
      adId,
      adSize: BannerAdSize.BANNER,
      position: BannerAdPosition.BOTTOM_CENTER,
      margin: 0,
      isTesting: this.isTesting,
      npa: this.npa,
    };

    AdMob.showBanner(options);
  }

  async showInterstitial() {
    AdMob.addListener(InterstitialAdPluginEvents.Loaded, (info: AdLoadInfo) => {
      // Subscribe prepared interstitial
      console.log('AdLoadInfo', info);
    });

    // android: ca-app-pub-6997870066717711/9450668974
    // ios: ca-app-pub-6997870066717711/6425637110

    const adId = 'ca-app-pub-6997870066717711/6425637110';

    const options: AdOptions = {
      adId,
      isTesting: this.isTesting,
      npa: this.npa,
    };
    await AdMob.prepareInterstitial(options);
    await AdMob.showInterstitial();
  }
}
