import board from 'bundle-text:./board.mustache';
import boardPill from 'bundle-text:./boardPill.mustache';
import controller from 'bundle-text:./controller.mustache';
import footer from 'bundle-text:./footer.mustache';
import header from 'bundle-text:./header.mustache';
import index from 'bundle-text:./index.mustache';
import main from 'bundle-text:./main.mustache';
import settings from 'bundle-text:./settings.mustache';

export { board, boardPill, controller, footer, header, index, main, settings };
