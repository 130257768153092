{
  "src": [
    "sprite/sounds.ogg",
    "sprite/sounds.m4a",
    "sprite/sounds.mp3",
    "sprite/sounds.ac3"
  ],
  "sprite": {
    "dock": [
      0,
      146.03174603174605
    ],
    "move": [
      2000,
      291.655328798186
    ],
    "open": [
      4000,
      175.01133786848033
    ],
    "rotate": [
      6000,
      1108.7755102040812
    ],
    "stageCleared": [
      9000,
      1201.6326530612246
    ],
    "zero": [
      12000,
      1149.3877551020405
    ]
  }
}