// controls.ts
import Game, { Settings } from './game';
import { Share } from '@capacitor/share';
import { onClick } from './helpers';

class Controls {
  private game: Game;
  private settingsModal: HTMLElement;

  constructor(game: Game) {
    this.game = game;

    const settingsModal = document.getElementById('settings');
    if (!settingsModal) {
      throw new Error('No settings modal');
    }
    this.settingsModal = settingsModal;
  }

  async init() {
    this.initializeGameControls();
    this.initializeSettingsModalControls();
  }

  private openSettingsModal() {
    this.settingsModal.style.display = 'block';
    this.game.pauseGame();
  }

  private closeSettingsModal() {
    this.settingsModal.style.display = 'none';
    this.game.resumeGame();
  }

  private initializeSettingsModalControls() {
    document.getElementById('shareButton')?.addEventListener('click', async () => {
      try {
        const { value: canShare } = await Share.canShare();
        if (canShare) {
          Share.share({
            title: 'Share title',
            text: 'Share text',
            url: 'https://example.com',
            // files: [''],
          });
        }
      } catch {
        // nothing to do here
      }
    });

    onClick('#playButton', async () => {
      this.closeSettingsModal();
      this.game.restart();
    });

    onClick('#settingsButton', async () => {
      this.openSettingsModal();
    });

    onClick('#closeSettingsModal', async () => {
      this.closeSettingsModal();
    });

    onClick('ul.radio > li', async ({ element: option }) => {
      if (!option.parentElement || !option.parentElement.dataset.name) {
        return;
      }

      const name = option.parentElement.dataset.name;
      const value = option.dataset.value || '';

      option.parentElement.querySelectorAll('li').forEach((option) => option.classList.remove('active'));
      option.classList.toggle('active', true);

      switch (name) {
        case 'difficulty':
          const difficulty = (['low', 'mid', 'high'].indexOf(value) ? value : 'low') as Settings['difficulty'];
          this.game.setSettingDifficulty(difficulty);
          break;
        case 'fx':
          this.game.setSettingFx(option.dataset.value === 'on');
          break;
        case 'music':
          this.game.setSettingMusic(option.dataset.value === 'on');
          break;
        case 'level':
          const level = option.dataset.value ? parseInt(option.dataset.value) : 1;
          this.game.setSettingLevel(level);
          break;
      }
    });
  }

  private initializeGameControls() {
    onClick('#a', async () => {
      this.game.rotatePill();
    });

    onClick('#b', async () => {
      this.game.rotatePill();
    });

    onClick('#up', async () => {
      this.game.rotatePill();
    });

    onClick('#left', async () => {
      this.game.movePillLeft();
    });

    onClick('#right', async () => {
      this.game.movePillRight();
    });

    onClick('#down', async () => {
      this.game.update();
    });

    onClick(document, async ({ x, y }) => {
      const partial = 7;

      if (y > 60 && y < window.innerHeight - 60) {
        if (x < window.innerWidth / partial) {
          this.game.movePillLeft();
        } else if (x > ((partial - 1) * window.innerWidth) / partial) {
          this.game.movePillRight();
        } else if (y > window.innerHeight - 160) {
          this.game.update();
        } else {
          this.game.rotatePill();
        }

        this.game.renderBoard();
      }
    });

    document.addEventListener('keydown', (event) => {
      if (!this.game.getIsPaused()) {
        switch (event.key) {
          case 'ArrowLeft':
            this.game.movePillLeft();
            break;
          case 'ArrowRight':
            this.game.movePillRight();
            break;
          case 'ArrowUp':
            this.game.rotatePill();
            break;
          case 'ArrowDown':
            this.game.update();
            break;
        }
        this.game.renderBoard(); // Re-render the game after a move or rotation
      }
    });
  }

  // Additional methods...
}

export default Controls;
