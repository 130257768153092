import { Howl, HowlOptions, SoundSpriteDefinitions } from 'howler';
import * as sounds from '../assets/sounds/sprite/sounds.json';
import { delay } from './helpers';

const howlOptions: HowlOptions = {
  sprite: sounds.sprite as unknown as SoundSpriteDefinitions,
  src: [
    new URL('../assets/sounds/sprite/sounds.ogg', import.meta.url).toString(),
    new URL('../assets/sounds/sprite/sounds.m4a', import.meta.url).toString(),
    new URL('../assets/sounds/sprite/sounds.mp3', import.meta.url).toString(),
    new URL('../assets/sounds/sprite/sounds.ac3', import.meta.url).toString(),
  ],
  preload: true,
};

class Sounds {
  private sounds: Howl | undefined;
  private onEndResolve: Record<number, () => void> = {};

  constructor() {
    document.addEventListener(
      'CLICK',
      () => {
        this.sounds = new Howl(howlOptions);
        this.sounds.on('end', (soundId) => {
          if (this.onEndResolve[soundId]) {
            this.onEndResolve[soundId]();
            delete this.onEndResolve[soundId];
          }
        });
      },
      { once: true },
    );
  }

  async play(name: keyof typeof sounds.sprite, options?: { delay?: number }): Promise<void> {
    return new Promise(async (resolve) => {
      if (!this.sounds) {
        resolve();
        return;
      }

      if (options?.delay) {
        await delay(options.delay);
      }

      const soundId = this.sounds.play(name);
      this.onEndResolve[soundId] = resolve;
    });
  }
}

export { Sounds };
