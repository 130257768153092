// game.ts
import { Sounds } from './sounds';

type Chamber = {
  type: 'empty' | 'chamber' | 'brick';
  number: number;
  x: number;
  y: number;
  markedForClearance?: boolean;
};

type Orientation = 'horizontalRight' | 'verticalUp' | 'horizontalLeft' | 'verticalDown';

type Pill = {
  chambers: [Chamber, Chamber]; // Two chambers
  orientation: Orientation;
};

export interface Settings {
  music: boolean;
  fx: boolean;
  difficulty: 'low' | 'mid' | 'high';
  level: number;
}

import { levels } from './config';

import { Preferences } from '@capacitor/preferences';
import { Ads } from './Ads';
import { delay, isMobile } from './helpers';

const squareImage = new Image();
squareImage.src = new URL('../assets/images/square.svg', import.meta.url).toString();

class Game {
  private isPaused: boolean = false;
  private board: Chamber[][] = [];
  private readonly rows = 15;
  private readonly columns = 8;
  private currentPill: Pill | null = null;
  private nextPill: Pill | null = null;
  private canvas: HTMLCanvasElement;
  private nextPillCanvas: HTMLCanvasElement;
  private chamberSize: number = 40;
  private updateIntervalId: number | null = null;
  private updateInterval: number = 1500; // Approximately 60 updates per second
  private backgroundMusic: HTMLAudioElement;
  private sounds = new Sounds();

  public settings: Settings = {
    music: true,
    fx: true,
    level: 0,
    difficulty: 'low',
  };

  private currentLevel: number = 1;
  private currentScore: number = 0;
  private highScore: number = 0;
  private isUpdating = false;

  private ctx: CanvasRenderingContext2D;
  private nextPillCtx: CanvasRenderingContext2D;
  private ads?: Ads;

  constructor(canvasId: string, nextPillCanvasId: string) {
    this.canvas = document.getElementById(canvasId) as HTMLCanvasElement;
    this.nextPillCanvas = document.getElementById(nextPillCanvasId) as HTMLCanvasElement;

    const ctx = this.canvas.getContext('2d');
    const nextPillCtx = this.nextPillCanvas.getContext('2d');

    if (!ctx || !nextPillCtx) {
      throw new Error('no ctx 2d context...');
    }

    this.ctx = ctx;
    this.nextPillCtx = nextPillCtx;

    this.adjustLayout();

    window.addEventListener('resize', () => {
      this.adjustLayout();
    });

    this.backgroundMusic = new Audio(new URL('../assets/music/theme.mp3', import.meta.url).toString());
    this.backgroundMusic.loop = true;

    if (isMobile) {
      this.ads = new Ads();
      this.ads.init().then(() => {
        this.ads?.showBanner();
      });
    }
  }

  public setupGame(currentLevel?: number) {
    const level = currentLevel || this.settings.level;
    const levelConfig = levels[`l${level}` as keyof typeof levels] || levels['l10'];

    this.currentLevel = level;
    this.currentPill = null;
    this.nextPill = null;
    this.board = this.createBoard();
    this.updateInterval = levelConfig.interval;
    this.updateInfoBoard();
    this.placeRandomBricks(levelConfig.bricks); // Place 7 random bricks
    this.nextPill = this.getNewPill();
    this.renderNextPill();
  }

  public setSettingLevel(level: Settings['level']) {
    this.settings.level = level;
    this.storeSettings();
  }

  public setSettingMusic(value: Settings['music']) {
    this.settings.music = value;
    this.storeSettings();
  }

  public setSettingFx(value: Settings['fx']) {
    this.settings.fx = value;
    this.storeSettings();
  }

  public setSettingDifficulty(value: Settings['difficulty']) {
    this.settings.difficulty = value;
    this.storeSettings();
  }

  private async storeSettings() {
    return Preferences.set({ key: 'settings', value: JSON.stringify(this.settings) });
  }

  updateInfoBoard() {
    const currentLevel = document.getElementById('currentLevel');
    const currentScore = document.getElementById('currentScore');
    const highScore = document.getElementById('highScore');

    if (currentLevel) {
      currentLevel.textContent = this.currentLevel.toString();
    }
    if (currentScore) {
      currentScore.textContent = this.currentScore.toString();
    }
    if (highScore) {
      highScore.textContent = this.highScore.toString();
    }
  }
  private placeRandomBricks(count: number) {
    let placed = 0;
    while (placed < count) {
      const x = Math.floor(Math.random() * this.columns);
      // Limit y to the bottom 3-fifth of the board
      const y = 5 + Math.floor(Math.random() * (this.rows - 5));

      // Only place a brick if the spot is empty
      if (this.board[y][x].type === 'empty') {
        this.board[y][x] = { type: 'brick', number: this.getRandomNumber(), x, y };
        placed++;
      }
    }
  }

  private adjustLayout() {
    const app = document.getElementById('app');
    if (!app) {
      throw new Error('No app container.');
    }

    const { width, height } = app.getBoundingClientRect();

    const maxWidth = Math.max(width, 320);
    const maxHeight = Math.max(height, 480);

    console.log(maxWidth, maxHeight);

    let availableWidth = 0;
    let availableHeight = 0;

    let chamberSize = 0;

    if (maxHeight > maxWidth) {
      // portrait
      app.classList.remove('landscape');
      app.classList.toggle('portrait', true);

      availableWidth = maxWidth - 10;
      availableHeight = maxHeight - 200;

      chamberSize = Math.floor(availableWidth / this.columns);
      if (chamberSize > availableHeight / this.rows) {
        chamberSize = Math.floor(availableHeight / this.rows);
      }
    } else {
      // landscape
      app.classList.remove('portrait');
      app.classList.toggle('landscape', true);

      availableHeight = maxHeight - 10;
      chamberSize = Math.floor(availableHeight / this.rows);
    }

    this.chamberSize = chamberSize;
    this.canvas.width = chamberSize * this.columns;
    this.canvas.height = chamberSize * this.rows;
    this.nextPillCanvas.width = chamberSize * 2;
    this.nextPillCanvas.height = chamberSize;
  }

  private createBoard(): Chamber[][] {
    return Array.from({ length: this.rows }, () =>
      Array.from({ length: this.columns }, () => ({ number: 0, x: 0, y: 0, type: 'empty' })),
    );
  }

  private getRandomNumber(min = -5, max = 5) {
    return Math.floor(Math.random() * (max - min) + min);
  }

  private getNewPill(): Pill {
    let number1 = 0;
    let number2 = 0;

    do {
      number1 = this.getRandomNumber();
      number2 = this.getRandomNumber();
    } while (number1 + number2 === 0);

    return {
      chambers: [
        { type: 'chamber', number: number1, x: Math.floor(this.columns / 2), y: 0 },
        { type: 'chamber', number: number2, x: Math.floor(this.columns / 2) + 1, y: 0 }, // Placed horizontally initially
      ],
      orientation: 'horizontalRight',
    };
  }

  private isCollision(pill: Pill): boolean {
    return pill.chambers.some((chamber) => {
      // Check if the chamber's position is already occupied
      return this.board[chamber.y][chamber.x].type !== 'empty';
    });
  }

  private renderNextPill() {
    this.nextPillCtx.clearRect(0, 0, this.nextPillCanvas.width, this.nextPillCanvas.height);
    this.nextPillCtx.canvas.style.opacity = '1';

    if (this.nextPill) {
      this.nextPill.chambers.forEach((chamber) => {
        if (chamber && this.nextPill) {
          // Temporarily adjust the x and y for rendering in the preview
          const originalX = chamber.x;
          const originalY = chamber.y;
          chamber.x = 1 + (chamber === this.nextPill.chambers[0] ? 0 : 1); // Center the pill
          chamber.y = 1;

          const offsetX = this.nextPillCanvas.width / 2 - this.chamberSize * 2;
          const offsetY = this.nextPillCanvas.height / 2 - this.chamberSize * 1.5;
          this.drawChamber(this.nextPillCtx, chamber, offsetX, offsetY);

          // Restore original x and y
          chamber.x = originalX;
          chamber.y = originalY;
        }
      });
    }
  }

  async init() {
    const settings = await Preferences.get({ key: 'settings' });
    if (settings.value) {
      this.settings = JSON.parse(settings.value);
    }

    const highScore = await Preferences.get({
      key: 'highScore',
    });
    this.highScore = highScore.value ? parseInt(highScore.value) : 0;
    this.setupGame();
  }

  start() {
    this.startGameLoop(); // Start the game loop
  }

  playMusic() {
    this.backgroundMusic.play();
  }

  pauseMusic() {
    this.backgroundMusic.pause();
  }

  toggleMusic() {
    if (this.backgroundMusic.paused) {
      this.backgroundMusic.play();
    } else {
      this.backgroundMusic.pause();
    }
  }

  restart() {
    this.stopGameLoop();
    this.setupGame();
    this.startGameLoop();
  }

  startGameLoop() {
    if (this.updateIntervalId === null) {
      this.updateIntervalId = window.setInterval(() => {
        if (!this.isPaused) {
          this.update();
        }
      }, this.updateInterval);
      return true;
    }
    return false;
  }
  stopGameLoop() {
    if (this.updateIntervalId !== null) {
      window.clearInterval(this.updateIntervalId);
      this.updateIntervalId = null;
      return true;
    }
    return false;
  }

  public getIsPaused() {
    return this.isPaused;
  }

  // Add methods to pause and resume the game
  pauseGame() {
    this.isPaused = true;
    this.stopGameLoop();
  }

  resumeGame() {
    this.isPaused = false;
    this.startGameLoop(); // Restart the game loop
  }

  private getDeletedChambers() {
    const deletedChambers: Chamber[] = [];
    for (let y = 0; y < this.rows; y++) {
      for (let x = 0; x < this.columns; x++) {
        const chamber = this.board[y][x];
        if (chamber.markedForClearance) {
          deletedChambers.push(chamber);
        }
      }
    }
    return deletedChambers;
  }

  private removeDeletedChambers = async (chambers: Chamber[]): Promise<void> => {
    return new Promise((resolve) => {
      if (chambers.length) {
        this.sounds.play('zero').then(() => {
          this.clearChambers(chambers);
          this.renderBoard();
          this.applyGravity().then(() => {
            this.checkLines();
            resolve();
          });
        });
      }
    });
  };

  async checkDeletedChambers() {
    let deletedChambers = this.getDeletedChambers();
    if (deletedChambers.length) {
      let eliminations = 0;
      do {
        let points = 0;
        eliminations++;
        deletedChambers.forEach((deletedChamber) => {
          points += Math.max(Math.abs(deletedChamber.number) * 2, 1);
        });
        this.currentScore += points * eliminations;

        if (this.currentScore > this.highScore) {
          Preferences.set({
            key: 'highScore',
            value: this.currentScore.toString(),
          });
        }
        this.updateInfoBoard();
        await this.removeDeletedChambers(deletedChambers);
        deletedChambers = this.getDeletedChambers();
      } while (deletedChambers.length > 0);
    }
  }

  private async releaseNewPill() {
    this.currentPill = this.nextPill;
    this.nextPill = this.getNewPill();
    this.renderNextPill();
  }

  async update() {
    if (this.isUpdating) {
      return;
    }
    this.isUpdating = true;

    if (this.currentPill && this.canMoveDown()) {
      // Move each chamber of the pill down
      this.currentPill.chambers.forEach((chamber) => chamber.y++);
      if (!this.canMoveDown()) {
        this.stopGameLoop();
        this.placePill();
        this.checkLines();
        await this.sounds.play('dock');
        await this.checkDeletedChambers();
        await delay(500);
        await this.releaseNewPill();
        this.startGameLoop();
      }
    } else {
      await this.releaseNewPill();
    }

    this.renderBoard();

    this.checkState();

    this.isUpdating = false;
  }

  private checkState() {
    const hasBricks = this.board.some((chambers) => {
      return chambers.some((chamber) => chamber.type === 'brick');
    });

    if (!hasBricks) {
      this.pauseGame();
      this.sounds.play('stageCleared').then(() => {
        this.setupGame(this.currentLevel + 1);
        this.resumeGame();
      });
    }
  }

  private canMoveDown(): boolean {
    if (!this.currentPill) return false;

    return this.currentPill.chambers.every((chamber) => {
      const nextY = chamber.y + 1;
      if (nextY >= this.rows) return false;
      return this.board[nextY][chamber.x].type === 'empty';
    });
  }

  private placePill() {
    if (this.currentPill) {
      this.currentPill.chambers.forEach((chamber) => {
        if (chamber.y >= 0) {
          this.board[chamber.y][chamber.x] = { ...chamber, type: 'chamber' };
        }
      });
      this.currentPill = null;
    }
  }

  rotatePill() {
    if (!this.currentPill) return;

    const originalOrientation = this.currentPill.orientation;

    const [chamber1, chamber2] = this.currentPill.chambers;

    if (!chamber1 || !chamber2) {
      return;
    }

    switch (this.currentPill.orientation) {
      case 'horizontalRight':
        // Rotate to verticalUp
        if (
          chamber1.y > 0 &&
          (!this.board[chamber1.y - 1][chamber1.x] || this.board[chamber1.y - 1][chamber1.x].number === 0)
        ) {
          chamber2.x = chamber1.x;
          chamber2.y = chamber1.y - 1;
          this.currentPill.orientation = 'verticalUp';
        }
        break;
      case 'verticalUp':
        // Rotate to horizontalLeft, shifting right if needed
        if (chamber1.x === 0) {
          // At the left edge, shift right
          if (
            (!this.board[chamber1.y][chamber1.x + 1] || this.board[chamber1.y][chamber1.x + 1].number === 0) &&
            (!this.board[chamber2.y][chamber2.x + 1] || this.board[chamber2.y][chamber2.x + 1].number === 0)
          ) {
            chamber1.x++;
            chamber2.x++;
          }
        }
        if (
          chamber1.x > 0 &&
          (!this.board[chamber1.y][chamber1.x - 1] || this.board[chamber1.y][chamber1.x - 1].number === 0)
        ) {
          chamber2.x = chamber1.x - 1;
          chamber2.y = chamber1.y;
          this.currentPill.orientation = 'horizontalLeft';
        }
        break;
      case 'horizontalLeft':
        // Rotate to verticalDown
        if (
          chamber1.y < this.rows - 1 &&
          (!this.board[chamber1.y + 1][chamber1.x] || this.board[chamber1.y + 1][chamber1.x].number === 0)
        ) {
          chamber2.x = chamber1.x;
          chamber2.y = chamber1.y + 1;
          this.currentPill.orientation = 'verticalDown';
        }
        break;
      case 'verticalDown':
        // Rotate back to horizontalRight, shifting left if needed
        if (chamber1.x === this.columns - 1) {
          // At the right edge, shift left
          if (
            (!this.board[chamber1.y][chamber1.x - 1] || this.board[chamber1.y][chamber1.x - 1].number === 0) &&
            (!this.board[chamber2.y][chamber2.x - 1] || this.board[chamber2.y][chamber2.x - 1].number === 0)
          ) {
            chamber1.x--;
            chamber2.x--;
          }
        }
        if (
          chamber1.x < this.columns - 1 &&
          (!this.board[chamber1.y][chamber1.x + 1] || this.board[chamber1.y][chamber1.x + 1].number === 0)
        ) {
          chamber2.x = chamber1.x + 1;
          chamber2.y = chamber1.y;
          this.currentPill.orientation = 'horizontalRight';
        }
        break;
    }

    if (originalOrientation !== this.currentPill.orientation) {
      this.renderBoard();
      this.sounds.play('rotate');
    }
  }

  movePillLeft() {
    if (!this.currentPill) return;

    // Check if both chambers can move left
    const canMove = this.currentPill.chambers.every((chamber) => {
      if (chamber.x <= 0) return false; // Left boundary check
      if (this.board[chamber.y][chamber.x - 1].type !== 'empty') return false; // Check for block on the left
      return true;
    });

    if (canMove) {
      this.sounds.play('move');
      this.currentPill.chambers.forEach((chamber) => chamber.x--);
      this.renderBoard();
    }
  }

  movePillRight() {
    if (!this.currentPill) return;

    let canMoveRight = true;

    // Check for each chamber
    for (const chamber of this.currentPill.chambers) {
      // If any chamber is at the right edge, or the space to the right is occupied, prevent movement
      if (chamber.x >= this.columns - 1 || this.board[chamber.y][chamber.x + 1].type !== 'empty') {
        canMoveRight = false;
        break;
      }
    }

    // Move the pill right if both chambers can move
    if (canMoveRight) {
      this.sounds.play('move');
      this.currentPill.chambers.forEach((chamber) => chamber.x++);
      this.renderBoard();
    }
  }

  private checkLines() {
    for (let y = 0; y < this.rows; y++) {
      this.accumulateAndEvaluateChambers(this.board[y]); // Row check
    }

    for (let x = 0; x < this.columns; x++) {
      const columnChambers = this.board.map((row) => row[x]); // Extract the column
      this.accumulateAndEvaluateChambers(columnChambers); // Column check
    }

    this.renderBoard();
  }

  private accumulateAndEvaluateChambers(chambers: Chamber[]) {
    for (let startIndex = 0; startIndex < chambers.length; startIndex++) {
      let sum = 0;
      let subset: Chamber[] = [];

      for (let endIndex = startIndex; endIndex < chambers.length; endIndex++) {
        const chamber = chambers[endIndex];
        if (chamber.type !== 'empty') {
          sum += chamber.number;
          subset.push(chamber);

          if (sum === 0 && subset.length >= 2) {
            subset.forEach((chamber) => {
              chamber.markedForClearance = true;
            });
          }
        } else {
          break; // Break on encountering a non-chamber, as continuity is broken
        }
      }
    }
  }
  private clearChambers(chambers: Chamber[]) {
    chambers.forEach((chamber) => {
      if (chamber) {
        this.board[chamber.y][chamber.x] = { type: 'empty', number: 0, x: chamber.x, y: chamber.y };
      }
    });
  }

  private async applyGravity(): Promise<void> {
    return new Promise((resolve) => {
      let moved = false;

      // Start from the second-to-last row and move upwards
      for (let y = this.rows - 2; y >= 0; y--) {
        for (let x = 0; x < this.columns; x++) {
          const chamber = this.board[y][x];
          if (chamber.type === 'chamber' && (!this.board[y + 1][x] || this.board[y + 1][x].type === 'empty')) {
            // Move the chamber down
            this.board[y + 1][x] = { ...chamber, y: y + 1 };
            this.board[y][x] = { type: 'empty', number: 0, x, y };

            moved = true;
          }
        }
      }

      // Recursively apply gravity if any chamber has moved
      if (moved) {
        this.sounds.play('move');
        this.renderBoard();
        setTimeout(async () => {
          await this.applyGravity();
          resolve();
        }, 250);
      } else {
        resolve();
      }
    });
  }

  private drawChamber(ctx: CanvasRenderingContext2D, chamber: Chamber, offsetX = 0, offsetY = 0) {
    if (chamber.type !== 'empty') {
      const x = chamber.x * this.chamberSize + offsetX;
      const y = chamber.y * this.chamberSize + offsetY;
      const borderRadius = chamber.type === 'brick' ? 9 : 1; // Adjust as needed

      // Draw a light grey background for bricks
      ctx.fillStyle = chamber.markedForClearance
        ? 'rgba(178, 222, 39, 0.25)'
        : chamber.type === 'brick'
        ? 'rgba(0, 0, 0, 0.95)'
        : 'rgba(0, 0, 0, 0.95)';

      ctx.beginPath();
      ctx.moveTo(x + borderRadius, y);
      ctx.arcTo(x + this.chamberSize, y, x + this.chamberSize, y + this.chamberSize, borderRadius);
      ctx.arcTo(x + this.chamberSize, y + this.chamberSize, x, y + this.chamberSize, borderRadius);
      ctx.arcTo(x, y + this.chamberSize, x, y, borderRadius);
      ctx.arcTo(x, y, x + this.chamberSize, y, borderRadius);
      ctx.closePath();
      ctx.fill();

      // Draw the rounded border
      const lineWidth = chamber.type === 'brick' ? 2 : 2;
      ctx.strokeStyle = chamber.markedForClearance
        ? 'rgba(255, 255, 255, 0.6)'
        : chamber.type === 'brick'
        ? 'rgba(255, 255, 255, 0.15)'
        : 'rgba(255, 255, 255, 0.15)';
      ctx.lineWidth = lineWidth;

      ctx.beginPath();
      ctx.moveTo(x + borderRadius, y);
      ctx.arcTo(x + this.chamberSize, y, x + this.chamberSize, y + this.chamberSize, borderRadius);
      ctx.arcTo(x + this.chamberSize, y + this.chamberSize, x, y + this.chamberSize, borderRadius);
      ctx.arcTo(x, y + this.chamberSize, x, y, borderRadius);
      ctx.arcTo(x, y, x + this.chamberSize, y, borderRadius);
      ctx.closePath();
      ctx.stroke();

      ctx.fillStyle = this.getChamberColor(chamber.number); // Adjust text color as needed
      ctx.font = `${this.chamberSize / 2 + 2}px LatoBold`;
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      ctx.fillText(
        `${chamber.number > 0 ? '+' : ''}${chamber.number}`,
        x + this.chamberSize / 2,
        y + this.chamberSize / 2,
      );
    }
  }

  private getChamberColor(number: number): string {
    // Example color scheme based on the number. You can customize this as needed.
    if (number > 0) {
      return 'white'; // Positive numbers
    } else if (number < 0) {
      return 'rgb(255, 76, 48)'; // Negative numbers
    } else {
      return 'green'; // Zero
    }
  }

  renderBoard() {
    // Clear the canvas
    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);

    // Draw the board
    for (let y = 0; y < this.rows; y++) {
      for (let x = 0; x < this.columns; x++) {
        const chamber = this.board[y][x];
        if (chamber.type === 'chamber' || chamber.type === 'brick') {
          this.drawChamber(this.ctx, chamber);
        }
      }
    }

    // Draw the current pill
    if (this.currentPill) {
      this.currentPill.chambers.forEach((chamber) => {
        if (chamber) {
          this.drawChamber(this.ctx, chamber);
        }
      });
    }
  }

  // Additional methods...
}

export default Game;
