export const isMobile = 'ontouchstart' in document.documentElement;
export const isIOS = isMobile && /iPad|iPhone|iPod/.test(navigator.userAgent);

type OnClickCallback = (data: { x: number; y: number; element: HTMLElement }) => Promise<void>;

const clicked = new CustomEvent('CLICK');

export const onClick = (selector: string | Document, cb: OnClickCallback) => {
  const addEventListener = (element: Element | Document) => {
    element.addEventListener(isMobile ? 'touchstart' : 'click', (ev) => {
      ev.preventDefault();
      ev.stopPropagation();
      if (ev.target) {
        if ((ev as TouchEvent).touches) {
          const x = (ev as TouchEvent).touches[0].clientX;
          const y = (ev as TouchEvent).touches[0].clientY;
          cb({ x, y, element: ev.target as HTMLElement });
        } else {
          const { x, y } = ev as MouseEvent;
          cb({ x, y, element: ev.target as HTMLElement });
        }
        document.dispatchEvent(clicked);
      }
    });
  };

  if (typeof selector === 'string') {
    document.querySelectorAll(selector).forEach((element) => {
      addEventListener(element);
    });
  } else {
    addEventListener(document);
  }
};

export const delay = async (ms: number) => {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
};
