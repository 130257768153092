export const levels = {
  l1: {
    interval: 1500,
    bricks: 3,
  },
  l2: {
    interval: 1400,
    bricks: 4,
  },
  l3: {
    interval: 1300,
    bricks: 5,
  },
  l4: {
    interval: 1200,
    bricks: 6,
  },
  l5: {
    interval: 1100,
    bricks: 7,
  },
  l6: {
    interval: 1000,
    bricks: 8,
  },
  l7: {
    interval: 900,
    bricks: 9,
  },
  l8: {
    interval: 800,
    bricks: 10,
  },
  l9: {
    interval: 700,
    bricks: 10,
  },
  l10: {
    interval: 600,
    bricks: 10,
  },
};
